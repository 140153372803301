const IntlMessageFormat = require('intl-messageformat');
const { localeManager } = require('@pcid/locale-utils');

const AUTO_FORMAT_MESSAGE_IDS = {
	mc: 'common.mc',
	pcid: 'common.pcid',
	contactUs: 'common.contactUs',
	signIn: 'form.common.signIn',
	cta: 'common.cta.resendCode',
};

/**
* intlString
* Formats an intl message into a string
* @param {string} messageId - id of the message
* @param {object} values - values to be interpolated into the message
* @param {string} lang - required language for the formatted message
*/
/* eslint-disable global-require, import/no-dynamic-require */
const intlString = (messageId, values = {}, lang = 'en') => {
	const messagesFile = require(`@pcid/cache/i18n/${lang}.json`);
	const message = messagesFile[messageId];
	try {
		return new IntlMessageFormat(message || messageId, lang).format(values || {});
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(`Unable to format messageId: ${messageId}`, e);
		return message;
	}
};

/**
* autoFormatMessage
* Same as formatMessage, but common values like pco, pcf, pcPlus etc, get automatically passed in
* @param {(object|string)} message - intl message object, or messageId to format
* @param {(string)} locale - the locale to render the message in, which defaults to the current
* 	locale, but can be overridden because analytics messages need to always be in English.
*/
const autoFormatMessage = (message = {}, locale = localeManager.getLanguage()) => {
	const { _case = 'sentence' } = message.values || {};
	const stubs = {
		id: 'id',
		idAria: '',
		mc: '',
		_case,
	};
	// format the common values, for the current locale,
	// returns a map of all AUTO_FORMAT_MESSAGE_IDS formatted for the current locale
	const values = Object
		.keys(AUTO_FORMAT_MESSAGE_IDS)
		.reduce((map, key) => ({
			[key]: intlString(AUTO_FORMAT_MESSAGE_IDS[key], { ...map, ...stubs }, locale),
			...map,
		}), {});

	if (typeof message === 'object') {
		return intlString(message.id, { ...values, ...stubs, ...message.values }, locale);
	}

	return message && intlString(message, values, locale);
};

module.exports = {
	autoFormatMessage,
	intlString,
};
