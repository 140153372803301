/*
 * String utils
 * ------------------------------
 * A series of useful utilities to move strings to
 * html and react components.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const justTheKids = { children: PropTypes.node.isRequired };

export const msg = (id, values) => <FormattedMessage id={id} values={values} />;
export const link = (to, id, values) => <a href={to}>{msg(id, values)}</a>;
export const em = (message) => <em>{message}</em>;
export const strong = (message) => <strong>{message}</strong>;
export const sup = (message) => <sup>{message}</sup>;
export const Apostrophe = () => <span>&#39;</span>;
export const Quote = ({ children }) => <span>&ldquo;{children}&rdquo;</span>;
Quote.propTypes = justTheKids;

// a11y string utils:
export const hide = (x) => (<span style={{ position: 'absolute', width: '1px', height: '1px', padding: '0', margin: '-1px', overflow: 'hidden', clip: 'rect(0,0,0,0)', whiteSpace: 'nowrap', border: '0' }} aria-hidden={false}>{x}</span>);
export const HiddenPeriod = () => hide('.');
export const HiddenColon = () => hide(':');
export const HiddenComma = () => hide(',');

/*
* Some helper functions for commonly used intl messages
* Note if you are importing these and passing them as values into a FormattedMessage
* or formatMessage,
* consider using AutoFormattedMessage or autoFormatMessage instead.
*/
export const md = () => sup(msg('common.md'));
export const mc = () => sup(msg('common.mc')); // The french equivalent of ™
export const pcEm = () => em(msg('common.pc'));
export const fpo = (id, values) => (<span className="fpo-banner">*FPO* - {msg(id, values)} - *FPO*</span>);
export const presidentsChoiceEm = () => em(msg('common.presidentsChoice'));

const PCID_VALUES = {
	mc: mc(),
	id: <span aria-hidden>id</span>,
	idAria: hide('ID'),
};

// The value `mc` is only used in French and replaces ™ from English
export const pcid = ({ _case = 'sentence' } = {}) => msg('common.pcid', { ...PCID_VALUES, _case });

export const AUTO_FORMATTED_MESSAGE_VALUES = {
	...PCID_VALUES,
	pc: pcEm(),
	presidentsChoice: presidentsChoiceEm(),
	pcid: pcid(),
	vhPeriod: <HiddenPeriod />,
	vhColon: <HiddenColon />,
	vhComma: <HiddenComma />,
};

/**
* AutoFormattedMessage
* Same as FormattedMessage, but common values like pco, pcf, pcPlus etc, get automatically passed in
* @param {object} props - props to pass to FormattedMessage
*/
export const AutoFormattedMessage = (props) => {
	const { values, ...rest } = props;
	return (
		<FormattedMessage
			{...rest}
			values={{
				...AUTO_FORMATTED_MESSAGE_VALUES,
				...values,
			}}
		/>
	);
};

AutoFormattedMessage.propTypes = {
	values: PropTypes.object,
};

AutoFormattedMessage.defaultProps = {
	values: {},
};

export const telephoneLink = (phoneNumber) => (
	<a href={`tel:+${phoneNumber}`}>{phoneNumber}</a>
);

export const emailLink = (emailAddress) => (
	<a href={`mailto:${emailAddress}`}>{emailAddress}</a>
);

// This allows us to place autoFormatMessage in a separate .js file without changing all imports
export { autoFormatMessage, intlString } from '@pcid/auto-format-message';
